<div
    class="preloader"
    id="loader"
    style="
        background-image: url('assets/img/preloader.gif');
        position: fixed;
        display: none;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 999999;
        background-color: rgb(255, 255, 255);
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
    "
></div>

<main class="main" style="font-size: large; font-weight: 500">
    <div
        class="container-fluid"
        style="
            background-color: #ffffff;
            padding-top: 12px;
            padding-bottom: 68px;
        "
    >
        <form [formGroup]="productForm" (ngSubmit)="Submit()">
            <div class="container app-header-custom">
                <div class="row">
                    <div class="col-md-2">
                        <p style="height: 100%; padding: 6px; margin: 0px">
                            <span class="fontcolor_red">Job ID</span>
                            {{ EditForm?.job_id }}
                        </p>
                    </div>
                    <div class="col-md-2">
                        <p style="height: 100%; padding: 6px; margin: 0px">
                            <span class="fontcolor_red">Insured:</span>
                            {{ EditForm?.insured_name }}
                        </p>
                    </div>
                    <div class="col-md-2">
                        <p style="height: 100%; padding: 6px; margin: 0px">
                            <span class="fontcolor_red">Regn No:</span>
                            {{ EditForm?.vehicle_reg_no }}
                        </p>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            </div>
            <!-- <div
      cdkDropList
      cdkDropListOrientation="horizontal"
      class="example-list"
      (cdkDropListDropped)="drop($event)"
  >
      <div class="row">
          <div class="col-2" *ngFor="let timePeriod of timePeriods" cdkDrag>
           
          </div>
      </div>
  </div> -->

            <div class="pl-5">
                <div
                    cdkDropList
                    class="example-list"
                    (cdkDropListDropped)="drop($event)"
                    formArrayName="form_field"
                >
                    <div class="row">
                        <div
                            *ngFor="
                                let item of formFieldArray().controls;
                                let i = index
                            "
                            [formGroupName]="i"
                        >
                            <ng-container
                                *ngIf="item.controls.form_field_label"
                            >
                                <label for=""></label>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                [attr.capture]="capture"
                                                class="d-none"
                                                (change)="
                                                    fileChange(
                                                        $event,
                                                        i,
                                                        'form_field_label',
                                                        item.controls
                                                            .form_field_label
                                                    )
                                                "
                                                id="form_field_label{{ i }}"
                                            />
                                            <i
                                                class="fa fa-times close cursor-pointer"
                                                *ngIf="
                                                    EditActive &&
                                                    ImageArray[i]
                                                        .form_field_label.custom
                                                "
                                                aria-hidden="true"
                                                (click)="removeQuantity(i)"
                                            ></i>
                                            <label
                                                class="text-center"
                                                for="form_field_label{{ i }}"
                                            >
                                                <img
                                                    class="rounded"
                                                    role="button"
                                                    [src]="
                                                        ImageArray[i]
                                                            .form_field_label
                                                            .src
                                                    "
                                                    accept="file_type | video/* | image/* | media_type"
                                                    alt=""
                                                    width="150"
                                                /><br />{{
                                                    ImageArray[i]
                                                        .form_field_label.name
                                                }}</label
                                            >

                                            <input
                                                type="hidden"
                                                formControlName="form_field_label"
                                            />
                                            <span
                                                class="form-text text-danger"
                                                *ngIf="
                                                    (item.controls
                                                        .form_field_label
                                                        .touched ||
                                                        submitted) &&
                                                    item.controls
                                                        .form_field_label.errors
                                                        ?.required
                                                "
                                            >
                                                *Required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-2">
              <button (click)="removeQuantity(i)" class="btn btn-danger">
                Remove
              </button>
            </div> -->
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container app-header-custom mt-3">
                <div class="row" *ngIf="EditActive">
                    <div class="col-md-3">Vehicle Images</div>
                    <div class="offset-md-4 col-md-3 mb-2">
                        <input
                            type="text"
                            [(ngModel)]="Add_image_field"
                            [ngModelOptions]="{ standalone: true }"
                            style="box-shadow: -1px -1px 5px grey"
                            maxlength="40"
                            class="form-control"
                            placeholder="Enter Name for Survey Checklist"
                            required
                        />
                    </div>
                    <div class="col-md-2">
                        <button
                            (click)="
                                addFormField(
                                    'assets/img/no-image.jpg',
                                    Add_image_field,
                                    true
                                )
                            "
                            type="button"
                            placeholder="&nbsp;Add Photos"
                            class="btn btn-success btn-block"
                        >
                            <i style="color: #fff" class="fa fa-plus"></i>Add
                            Image Field
                        </button>
                    </div>
                </div>
            </div>

            <div class="p-5">
                <div class="example-list row" formArrayName="form_field">
                    <div class="row">
                        <div
                            *ngFor="
                                let item of formFieldArray().controls;
                                let i = index
                            "
                            [formGroupName]="i"
                        >
                            <ng-container
                                *ngIf="item.controls.form_document_label"
                            >
                                <div class="col-md-3">
                                    <div class="input-group">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            [attr.capture]="capture"
                                            class="d-none"
                                            (change)="
                                                fileChange(
                                                    $event,
                                                    i,
                                                    'form_document_label',
                                                    item.controls
                                                        .form_document_label
                                                )
                                            "
                                            id="form_field_label{{ i }}"
                                        />
                                        <i
                                            class="fa fa-times close document cursor-pointer"
                                            aria-hidden="true"
                                            *ngIf="
                                                EditActive &&
                                                ImageArray[i]
                                                    .form_document_label.custom
                                            "
                                            (click)="removeQuantity(i)"
                                        ></i>
                                        <label
                                            for="form_field_label{{ i }}"
                                            class="text-center"
                                        >
                                            <img
                                                class="rounded"
                                                [src]="
                                                    ImageArray[i]
                                                        .form_document_label.src
                                                "
                                                accept="file_type | audio/* | video/* | image/* | media_type"
                                                alt=""
                                                width="150"
                                            /><br />{{
                                                ImageArray[i]
                                                    .form_document_label.name
                                            }}</label
                                        >

                                        <input
                                            type="hidden"
                                            formControlName="form_document_label"
                                        />
                                        <span
                                            class="form-text text-danger"
                                            *ngIf="
                                                (item.controls
                                                    .form_document_label
                                                    .touched ||
                                                    submitted) &&
                                                item.controls
                                                    .form_document_label.errors
                                                    ?.required
                                            "
                                        >
                                            *Required
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container app-header-custom mb-5">
                <div class="row" *ngIf="EditActive">
                    <div class="col-md-3">Vehicle Document</div>
                    <div class="offset-md-4 col-md-3 mb-2">
                        <input
                            type="text"
                            [(ngModel)]="Document_field"
                            [ngModelOptions]="{ standalone: true }"
                            style="box-shadow: -1px -1px 5px grey"
                            maxlength="40"
                            class="form-control"
                            placeholder="Enter Name for Survey Checklist"
                            required
                        />
                    </div>
                    <div class="col-md-2">
                        <button
                            (click)="
                                addDocumentField(
                                    'assets/img/no-image.jpg',
                                    Document_field,
                                    true
                                )
                            "
                            type="button"
                            placeholder="&nbsp;Add Photos"
                            class="btn btn-success btn-block"
                            
                        >
                            <i style="color: #fff" class="fa fa-plus"></i>Add
                            Document Field
                        </button>
                    </div>
                </div>
            </div>
            
            <div class="container app-header-custom" *ngIf="isVideoActive">
                <div class="row">
                    <div class="col-md-5">
                        <label>Survey Video <p class="text-danger">(Video should not exceed more than 30 Seconds)</p></label>
                        <div class="input-group p-1" >
                            <input
                                formControlName="video_file"
                                type="file"
                                (change)="onFileChange($event)"
                                style="box-shadow: -1px -1px 5px grey"
                                class="form-control"
                                value="VideoIsAllowed"
                                accept="video/mp4,video/x-m4v,video/*"
                                capture="camera"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container app-header-custom mt-3">
                <div class="row my-3" >
                    <div class="col-md-12">Job Remark</div>
                </div>
            </div>
            <div class="container">
                <div class="row m-3">
                    <div class="col-md-12">
                        <ckeditor
                            [config]="{placeholder: 'Job Remark' ,  toolbar: [ 'heading','undo', 'redo', 'bold', 'italic', 'numberedList', 'bulletedList','fontBackgroundColor','blockQuote','indent','link','wproofreader' ] }"
                            id="editor"
                            formControlName="job_remark"   
                            [editor]="Editor"
                            data=""
                        ></ckeditor>
                    </div>
                </div>
            </div>
        
            <div class="container app-header-custom">
                <div class="row">
                    <div class="offset-md-10 col-md-2 mt-md-2">
                        <button type="submit" class="btn btn-danger btn-block">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            

            <div
                bsModal
                #primaryModal="bs-modal"
                class="modal fade"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content" style="border-color: white">
                        <div class="modal-body">
                            <div class="col-md-12 mx-auto">
                                <div class="card-body">
                                    <ng-container *ngIf="imageURL">
                                        <app-image-editor
                                            [inputImage]="imageURL"
                                        ></app-image-editor>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</main>
