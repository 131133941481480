import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { Jobs } from "../../Interface/Jobs/jobs";
import { Constant } from "../../Shared/Models/constant";

@Injectable({
    providedIn: "root",
})
export class JobsService {
    Jobs: Observable<Jobs[]>;
    private url: string;
    Constants = new Constant();

    private Addnewjobs = this.Constants.localhost_url + "api/jobms";
    private Deletejobs = this.Constants.localhost_url + "api/job/";
    private NotYetAssigned = this.Constants.localhost_url + "api/jobms";
    private Assigned = this.Constants.localhost_url + "api/jobms";
    private Tobeapproved = this.Constants.localhost_url + "api/jobms";
    private Rejected = this.Constants.localhost_url + "api/jobms";
    private Approved = this.Constants.localhost_url + "api/jobms";
    private Completed = this.Constants.localhost_url + "api/jobms";
    private Detail = this.Constants.localhost_url + "api/jobms/";

    private VehicleDetail =
        this.Constants.localhost_url + "api/job/get-vehicle-detail";
    private VehicleVariant =
        this.Constants.localhost_url + "api/job/vehicle-variants/";

    private approvedstatusedit =
        this.Constants.localhost_url + "api/job/update-job/";
    private updateJob = this.Constants.localhost_url + "api/job/update-job/";
    private assignedemployee =
        this.Constants.localhost_url + "api/job-assign-ms";
    private jobeditdetail = this.Constants.localhost_url + "api/jobms/";
    private inspectionDetail =
        this.Constants.localhost_url + "api/update-inspection";
    private approvedinpectionsave =
        this.Constants.localhost_url + "api/ms/post/tab-data";
    private approvedinspectionget =
        this.Constants.localhost_url + "api/ms/get/tab-data";
    private rejectJob = this.Constants.localhost_url + "api/job/";
    private clientbranch = this.Constants.localhost_url + "api/clientbranch";
    private updateRemark =
        this.Constants.localhost_url + "api/job/update-remark/";
    private sentReportMail =
        this.Constants.localhost_url + "api/job/send-mail-report";
    private updateJobImageUrl =
        this.Constants.localhost_url + "api/job/update-job-image/";
    private contactPerson = this.Constants.localhost_url + "api/contactperson";
    private inspectionDetailbyid =
        this.Constants.localhost_url + "api/get-job-by-id/";
    private fileUpload = this.Constants.localhost_url + "api/file_upload";
    private manualUpload = this.Constants.localhost_url + "api/manual-upload";
    private surveyLink = this.Constants.localhost_url + "api/job-link-create/";
    private assessmentLink = this.Constants.localhost_url + "api/assessment/";
    private taxDepSetting = this.Constants.localhost_url + "api/taxdepsetting/";
    private isZeroDepSetting = this.Constants.localhost_url + "api/gettaxdep/";
    private inspectionjobstatus =
        this.Constants.localhost_url + "api/inspection-jobstatus/";
    private summaryLink =
        this.Constants.localhost_url + "api/getassessmentsummary/";
    private summaryDataSave =
        this.Constants.localhost_url + "api/assesment-inputupdate";
    private storeAttachmentMaster =
        this.Constants.localhost_url + "api/storeattachmentmaster";
    private deleteMasterAttachment =
        this.Constants.localhost_url + "api/deletemasterattachment?";
    private saveDynamicSectionMaster =
        this.Constants.localhost_url + "api/savedynamicsectionmaster";
    private deleteDynamicSectionMaster =
        this.Constants.localhost_url + "api/deletedynamicsectionmaster?";
    private getDynamicSectionMasterData =
        this.Constants.localhost_url + "api/getdynamicsectionmasterdata";
    private vehicleApiData =
        this.Constants.localhost_url + "api/getvehicledata";
    private getJobDetailsList =
        this.Constants.localhost_url + "api/get_job_details_list";
    private getStructureList =
        this.Constants.localhost_url + "api/ms/get-estimate-structure";
    private storeStructureData =
        this.Constants.localhost_url + "api/ms/import-estimate-structure";
    private base64URL = this.Constants.localhost_url + "api/ms/get/base-encode";
    private getStructureInfo =
        this.Constants.localhost_url + "api/ms/estimate-structure-details/";
    private feeBillRefreshURL =
        this.Constants.localhost_url + "api/fee-bill-calculation/";
    private uploadPdfURL = this.Constants.localhost_url.replace('backend/','') +"api/extract_tables";
    private uploadExcellURL = this.Constants.localhost_url.replace('backend/','') +"api/extract_tables_excel";
    private AddLocalWorkshop = this.Constants.localhost_url+"api/add-local-workshop";

    private EditLocalWorkshop = this.Constants.localhost_url+"api/edit-local-workshop/";

    private AddPrivateTemplate = this.Constants.localhost_url+"api/ms/add-pod-template";

    private replaceTagURL = this.Constants.localhost_url+"api/ms/replace-tags-data/";

    private GetPrivateTemplate = this.Constants.localhost_url+"api/ms/pod-template-list/"

    constructor(private httpClient: HttpClient) {}

    contactpersonlist(id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(
            `${this.contactPerson + "?all=&branch_id="}` + id,
            { headers: header }
        );
    }

    getBase64URL(urlData): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        let file = {
            url: urlData,
        };
        return this.httpClient.post(`${this.base64URL}`, file, {
            headers: header,
        });
    }
    vehicleData(
        vehicle_make,
        vehicle_variant = "",
        vehicle_model = ""
    ): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(
            `${this.vehicleApiData}?vehicle_make=${vehicle_make}&vehicle_variant=${vehicle_variant}&vehicle_model=${vehicle_model}`,
            { headers: header }
        );
    }

    getJobDetails() {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.getJobDetailsList}`, {
            headers: header,
        });
    }

    structureList(): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.getStructureList}`, {
            headers: header,
        });
    }
    Addjobs(Addjobsdata: Object): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.Addnewjobs}`, Addjobsdata, {
            headers: header,
        });
    }

    storeStructure(formValue: Object): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.storeStructureData}`, formValue, {
            headers: header,
        });
    }

    getStructureData(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.getStructureInfo}` + id, {
            headers: header,
        });
    }
    storeAttachment(Addattachment: Object): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(
            `${this.storeAttachmentMaster}`,
            Addattachment,
            { headers: header }
        );
    }
    deleteAttachment(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.delete(
            `${this.deleteMasterAttachment}key=` + id,
            { headers: header }
        );
    }

    JobsNotYetAssigned(
        PageNo: string,
        SearchTxt: string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.NotYetAssigned +
                "?status=created&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo
            }`,
            { headers: header }
        );
    }

    JobsAssigned(
        PageNo: string,
        SearchTxt: string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Assigned +
                "?status=pending&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo
            }`,
            { headers: header }
        );
    }

    JobsTobeapproved(
        PageNo: string,
        SearchTxt: string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Tobeapproved +
                "?status=submitted&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo
            }`,
            { headers: header }
        );
    }

    JobsRejected(
        PageNo: string,
        SearchTxt: string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Rejected +
                "?status=rejected&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo
            }`,
            { headers: header }
        );
    }

    JobsApproved(
        PageNo: string,
        SearchTxt: string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Approved +
                "?status=approved&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo
            }`,
            { headers: header }
        );
    }

    JobsCompleted(
        PageNo: string,
        SearchTxt: string,
        FromDate: string,
        ToDate: string,
        isOffline: boolean
    ): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        var offline = "no";
        if (isOffline) {
            offline = "yes";
        }

        return this.httpClient.get(
            `${
                this.Completed +
                "?status=finalized&is_offline=" +
                offline +
                "&search_keyword=" +
                SearchTxt +
                "&from_date=" +
                FromDate +
                "&to_date=" +
                ToDate +
                "&page=" +
                PageNo
            }`,
            { headers: header }
        );
    }

    JobDelete(id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.delete(`${this.Deletejobs}` + id, {
            headers: header,
        });
    }

    MasterDelete(id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.delete(
            `${this.deleteDynamicSectionMaster}id=` + id,
            { headers: header }
        );
    }

    JobDetail(id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.Detail}` + id, { headers: header });
    }

    MasterDetail(): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.getDynamicSectionMasterData}`, {
            headers: header,
        });
    }

    SaveMasterDetail(formData): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(
            `${this.saveDynamicSectionMaster}`,
            formData,
            { headers: header }
        );
    }

    InspectionDetailbyid(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.inspectionDetailbyid}` + id, {
            headers: header,
        });
    }

    AssesmentInspectionDetailbyid(formName, id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(
            `${this.approvedinspectionget}?type=${formName}&inspection_id=` +
                id,
            { headers: header }
        );
    }

    UpdateJobRemark(id: string, obj: object): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.put(`${this.updateRemark}` + id, obj, {
            headers: header,
        });
    }

    CreateSurveyLink(id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.surveyLink}` + id, {
            headers: header,
        });
    }

    ApprovedStatusEdit(id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.put(
            `${this.rejectJob}` + id + "/approved",
            {},
            { headers: header }
        );

        /*
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.put(`${this.approvedstatusedit}`+id, approvededit, { headers: header });
    */
    }

    CompletedStatusEdit(id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );

        return this.httpClient.get(
            `${this.rejectJob + "finalize-report/"}` + id,
            { headers: header }
        );

        /*
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.put(`${this.approvedstatusedit}`+id, approvededit, { headers: header });
    */
    }
    SummaryDataSave(formData): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.summaryDataSave}`, formData, {
            headers: header,
        });
    }

    SentMailReport(employeeedit: object, jobid: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.sentReportMail}`, employeeedit, {
            headers: header,
        });
    }

    AssignedEmployeelist(
        employeeedit: object,
        jobid: string,
        AssignName
    ): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.assignedemployee}`, employeeedit, {
            headers: header,
        });
    }

    jobEditDetail(jobdata: Object, id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.put(`${this.jobeditdetail}` + id, jobdata, {
            headers: header,
        });
    }
    InspectionDetail(jobdata: Object): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.inspectionDetail}`, jobdata, {
            headers: header,
        });
    }
    ApprovedFormSubmit(jobdata: Object): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.approvedinpectionsave}`, jobdata, {
            headers: header,
        });
    }

    jobUpdateDetail(jobdata: Object, id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.put(`${this.updateJob}` + id, jobdata, {
            headers: header,
        });
    }

    rejectJobService(id: string, remark: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.put(
            `${this.rejectJob}` + id + "/rejected",
            { remark: remark },
            { headers: header }
        );
    }

    getVehicleDetails(obj: object): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.VehicleDetail}`, obj, {
            headers: header,
        });
    }

    getVehicleVariant(obj: object, id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.VehicleVariant}` + id, obj, {
            headers: header,
        });
    }

    branchlist(id: string): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(
            `${this.clientbranch + "?all=&client_id="}` + id,
            { headers: header }
        );
    }

    updateJobImage(
        file: File,
        jobid: string,
        imageid: string,
        type: string
    ): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );

        let formParams = new FormData();
        formParams.append("file", file);
        formParams.append("type", type);
        formParams.append("image_id", imageid);
        return this.httpClient.post(
            `${this.updateJobImageUrl}` + jobid,
            formParams,
            { headers: header }
        );
    }

    uploadJobDoc(Base64Image): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.fileUpload}`, Base64Image, {
            headers: header,
        });
    }

    UploadJob(fileList): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.manualUpload}`, fileList, {
            headers: header,
        });
    }
    AssessmentDetail(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.assessmentLink}` + id, {
            headers: header,
        });
    }
    SummaryLink(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.summaryLink}` + id, {
            headers: header,
        });
    }
    TaxDepSetting(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.taxDepSetting}` + id, {
            headers: header,
        });
    }

    isZeroDepSettings(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(
            `${this.isZeroDepSetting}` + id + "?IsZeroDep=1",
            { headers: header }
        );
    }
    SaveTaxDepSetting(FileData, id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.taxDepSetting}` + id, FileData, {
            headers: header,
        });
    }
    InspectionApproved(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.inspectionjobstatus}` + id, {
            headers: header,
        });
    }

    AssessmentSubmit(FileData, id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.assessmentLink}` + id, FileData, {
            headers: header,
        });
    }

    uploadPDf(FileData,extension): Observable<any> {
        let header = new HttpHeaders({
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Access-Control-Allow-Origin':'*'
           
        });
        let url = extension=="pdf"?this.uploadPdfURL:this.uploadExcellURL;
        return this.httpClient.post(`${url}`, FileData, {
            headers: header,
        });
    }

    feeBillRefresh(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.feeBillRefreshURL}${id}`, {
            headers: header,
        });
    }

    getLocalWorkshop(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.EditLocalWorkshop}${id}`, {
            headers: header,
        });
    }

    getTemplateDetails(id): Observable<any> {
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.GetPrivateTemplate}${id}`, {
            headers: header,
        });
    }

    storeLocalWorkshop(FileData): Observable<any>{
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.AddLocalWorkshop}`, FileData, {
            headers: header,
        });
    }

    storeTemplate(FileData): Observable<any>{
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.post(`${this.AddPrivateTemplate}`, FileData, {
            headers: header,
        });
    }
    replaceTagData(id):Observable<any>{
        let header = new HttpHeaders().set(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
        );
        return this.httpClient.get(`${this.replaceTagURL}${id}`, {
            headers: header,
        });
    }
}
