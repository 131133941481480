import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constant } from '../../Shared/Models/constant';
import { SOP } from '../Interface/SOP/Sop';

@Injectable({
  providedIn: 'root'
})
export class SopService {
  SOP:Observable<SOP[]>
  private url: string;
  Constants = new Constant();


  private SOPaddnew = this.Constants.localhost_url + 'api/sop'
  private Delete = this.Constants.localhost_url + 'api/sop/'
  private SOPlists = this.Constants.localhost_url + 'api/sopall/'
  private SOPBranchList = this.Constants.localhost_url + 'api/sop-branchid/'
  private SOPDetail = this.Constants.localhost_url + 'api/sop/'
  private SOPEdit = this.Constants.localhost_url + 'api/sop/'
  private ImageDocumentDetail = this.Constants.localhost_url + 'api/get-job-files'
  private SurveyDetail = this.Constants.localhost_url + 'api/job-link-get/'
  private SaveSurveyDeatil = this.Constants.localhost_url+'api/job-upload-link'
  constructor(private httpClient: HttpClient) { }

  SOPnew(SOPdata:Object): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.post(`${this.SOPaddnew}`,SOPdata,{ headers: header });
  }



   SOPlist(PageNo:string='all',SearchTxt:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.SOPlists +SearchTxt +'?page=' + PageNo}`,{ headers: header });
  }

  SOPBranchlists(PageNo:string,SearchTxt:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.SOPBranchList +SearchTxt +'?page=' + PageNo}`,{ headers: header });
  }

  SOPGetDetail(id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.SOPDetail}`+id, { headers: header });
  }

  GetImageDocumentDetails(id:string): Observable<any>{
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.ImageDocumentDetail}?inspection_id=`+id+'', { headers: header });
  }

  SOPEditDetail(SOPEditData:Object,id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.put(`${this.SOPEdit}`+id,SOPEditData, { headers: header });
  }

  
  SOPDelete(id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.delete(`${this.Delete}`+id, { headers: header });
  }

  GetSurveyDetails(id:string): Observable<any> {
    let header = new HttpHeaders().set(
      'Authorization', `Bearer ${localStorage.getItem('token')}`
    );
    return this.httpClient.get(`${this.SurveyDetail}`+id, { headers: header });
   // return this.httpClient.get(`${this.SurveyDetail}`+id);
  }
  SaveSurveyDetails(SurveyLinkData): Observable<any>{
    // let header = new HttpHeaders().set(
    //   'Authorization', `Bearer ${localStorage.getItem('token')}`
    // );
    return this.httpClient.post(`${this.SaveSurveyDeatil}`, SurveyLinkData );
    
  }



}
